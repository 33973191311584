@charset 'utf-8';

@import 'settings';
@import 'foundation';


@include foundation-global-styles;
@include foundation-grid;
@include foundation-typography;
@include foundation-button;

@include foundation-visibility-classes;
@include foundation-float-classes;
@include foundation-badge;
@include foundation-button-group;

@include foundation-close-button;

@include foundation-dropdown;
@include foundation-dropdown-menu;
@include foundation-flex-video;

@include foundation-media-object;
@include foundation-menu;
@include foundation-off-canvas;
@include foundation-pagination;
@include foundation-reveal;

@include foundation-title-bar;

@include foundation-top-bar;




/*********************************************************************************************

Mixins

**********************************************************************************************/


/* Transition */

@mixin transition($transition...) {
	// defining prefixes so we can use them in mixins below
	$prefixes:      ("-webkit", "-ms", "-o",  "");
  @each $prefix in $prefixes {
    #{$prefix}-transition: $transition;
  }

  transition: $transition;
}

/* Image Size */

@mixin background-size($size) {
background-size: $size!important;
-moz-background-size: $size!important;
-o-background-size: $size!important;
-webkit-background-size: $size!important;
}


/* Typography */

/* Light */ 

@font-face {
    	font-family: 'National';
    	src: url('../fonts/national/NationalWeb-Light.otf');
    	src: url('../fonts/national/national/NationalWeb-Light.eot?#iefix') format('embedded-opentype'),
             url('../fonts/national/NationalWeb-Light.woff') format('woff'),
             url('../fonts/national/NationalWeb-Light.ttf') format('truetype'),
             url('../fonts/national/NationalWeb-Light.svg#font-name') format('svg');
             
        font-weight: 200;
        font-style: normal;
 
	}

/* Light italic */ 

	@font-face {
    	font-family: 'National';
    	src: url('../fonts/national/NationalWeb-LightItalic.otf');
    	src: url('../fonts/national/national/NationalWeb-LightItalic.eot?#iefix') format('embedded-opentype'),
             url('../fonts/national/NationalWeb-LightItalic.woff') format('woff'),
             url('../fonts/national/NationalWeb-LightItalic.ttf') format('truetype'),
             url('../fonts/national/NationalWeb-LightItalic.svg#font-name') format('svg');
             
        font-weight: 200;
        font-style: italic;
 
	}

$primary-color: #13143d;

/* Regular */ 

@font-face {
    	font-family: 'National';
    	src: url('../fonts/national/NationalWeb-Regular.otf');
    	src: url('../fonts/national/national/NationalWeb-Regular.eot?#iefix') format('embedded-opentype'),
             url('../fonts/national/NationalWeb-Regular.woff') format('woff'),
             url('../fonts/national/NationalWeb-Regular.ttf') format('truetype'),
             url('../fonts/national/NationalWeb-Regular.svg#font-name') format('svg');
             
        font-weight: 300;
        font-style: normal;
 
	}

/* Regular italic */ 

	@font-face {
    	font-family: 'National';
    	src: url('../fonts/national/NationalWeb-RegularItalic.otf');
    	src: url('../fonts/national/national/NationalWeb-RegularItalic.eot?#iefix') format('embedded-opentype'),
             url('../fonts/national/NationalWeb-RegularItalic.woff') format('woff'),
             url('../fonts/national/NationalWeb-RegularItalic.ttf') format('truetype'),
             url('../fonts/national/NationalWeb-RegularItalic.svg#font-name') format('svg');
             
        font-weight: 300;
        font-style: italic;
 
	}


	/* Medium */ 

@font-face {
    	font-family: 'National';
    	src: url('../fonts/national/NationalWeb-Medium.otf');
    	src: url('../fonts/national/national/NationalWeb-Medium.eot?#iefix') format('embedded-opentype'),
             url('../fonts/national/NationalWeb-Medium.woff') format('woff'),
             url('../fonts/national/NationalWeb-Medium.ttf') format('truetype'),
             url('../fonts/national/NationalWeb-Medium.svg#font-name') format('svg');
             
        font-weight: 400;
        font-style: normal;
 
	}

/* Medium italic */ 

	@font-face {
    	font-family: 'National';
    	src: url('../fonts/national/NationalWeb-MediumItalic.otf');
    	src: url('../fonts/national/national/NationalWeb-MediumItalic.eot?#iefix') format('embedded-opentype'),
             url('../fonts/national/NationalWeb-MediumItalic.woff') format('woff'),
             url('../fonts/national/NationalWeb-MediumItalic.ttf') format('truetype'),
             url('../fonts/national/NationalWeb-MediumItalic.svg#font-name') format('svg');
             
        font-weight: 400;
        font-style: italic;
 
	}


		/* Bold */ 

@font-face {
    	font-family: 'National';
    	src: url('../fonts/national/NationalWeb-Bold.otf');
    	src: url('../fonts/national/national/NationalWeb-Bold.eot?#iefix') format('embedded-opentype'),
             url('../fonts/national/NationalWeb-Bold.woff') format('woff'),
             url('../fonts/national/NationalWeb-Bold.ttf') format('truetype'),
             url('../fonts/national/NationalWeb-Bold.svg#font-name') format('svg');
             
        font-weight: 500;
        font-style: normal;
 
	}

/* Bold italic */ 

	@font-face {
    	font-family: 'National';
    	src: url('../fonts/national/NationalWeb-BoldItalic.otf');
    	src: url('../fonts/national/national/NationalWeb-BoldItalic.eot?#iefix') format('embedded-opentype'),
             url('../fonts/national/NationalWeb-BoldItalic.woff') format('woff'),
             url('../fonts/national/NationalWeb-BoldItalic.ttf') format('truetype'),
             url('../fonts/national/NationalWeb-MediumItalic.svg#font-name') format('svg');
             
        font-weight: 500;
        font-style: italic;
 
	}



/*********************************************************************************************

Structural

**********************************************************************************************/

.wrapper {
	
	padding: rem-calc(50 0);
	position:relative;

}

.section-wrap {
	
	padding:rem-calc(25 0 );
	position:relative;
	
	@include breakpoint(medium) {
		
		padding:rem-calc(75 0 );

	}

}

.section-half-wrap {
	
	padding:rem-calc(25 0 );
	position:relative;
	
	@include breakpoint(medium) {

		padding:rem-calc(75 25 );
		position:relative;
		

	}

}

.head-end {
	
	    display: block;
    overflow: hidden;
    width: 100%;
    height: 1px;
    opacity: 0;
}

.resp {
	
	max-width: 100%;
	min-width: 100%;

}

.mb {
	
	margin-bottom: rem-calc(25);
}


.text-area {
	

	
	h3 {
		
		
		margin-bottom: rem-calc(25);
		margin-top:0;
		

	}
	
	a {
		
		border-bottom: 2px solid $secondary-color;
		font-weight:500;
	}
}



	
	.text-area-single {
		
		border-right: none;
		padding: rem-calc(25 0 50 0);
	}



/*Background Colour*/

.col-tint {
	
	background: scale-color($primary-color, $lightness: 96%);
}


.col-dark{
	
	background: $primary-color;
	color:$white;
}

.palette-secondary {
	
	background: $blue;
}

.palette-light {
	
	background: $light-gray;
}

.hidden { display:none; }


/*********************************************************************************************

Typography

**********************************************************************************************/


/* Titling */


h1, h2, h3, h4, h5 {
	

	a {
		
		text-decoration:none;
		border-bottom:none!important;
		color:inherit;
		
		&:hover {
			
			color: $red;
		}
	}

}


.h1, h1 {


letter-spacing: rem-calc(0.25);
line-height:1.2;
margin-top: rem-calc(0);
margin-bottom: rem-calc(25);
font-weight: 400;
color:inherit;

@include breakpoint(medium) {
	
line-height: rem-calc(50);
margin-bottom: rem-calc(50);
	}


}

.h2, h2 {
	
letter-spacing: rem-calc(0.15);
line-height: 1.2;
margin-top: rem-calc(12.5);
margin-bottom: rem-calc(25);
font-weight: 200;
 
@include breakpoint(medium) {
	
	line-height: 1.4;
	margin-top: rem-calc(25);
	margin-bottom: rem-calc(25);

	}

}


.h3, h3{

letter-spacing: 0px;

margin-top: rem-calc(12.5);
margin-bottom: rem-calc(12.5);
font-weight: 400;
color:inherit;

@include breakpoint(medium) {
	
	margin-top: rem-calc(25);
	margin-bottom: rem-calc(25);


	}
	

}


.h4, h4, h5 {

line-height: rem-calc(25);
margin-top: rem-calc(25);
margin-bottom: rem-calc(25);
color:inherit;
	

}


.masthead{
	
	@extend.h1;
	
	font-weight: 400;
	color: $white;
	font-size: rem-calc(52);
	
	@include breakpoint(medium ) {

	font-size: rem-calc(76);
	line-height:rem-calc(75);
		
		}
	
}

.section-header {
	
	@extend.h1;
	
	font-weight: 400;
	margin-bottom: rem-calc(25);
	
	
	@include breakpoint(medium ) {

	
	font-size: rem-calc(60);
	margin-bottom: rem-calc(50);
		
		}
	
}


.sml-head {
	
	font-size: rem-calc(18);
	text-transform: uppercase;
	font-weight: 600;

}


/* Activity headers*/

.activity--astro-photo, .activity--running, .activity--astronomy, .activity--sun {
	
	@include background-size(24px 24px);
	padding-left: 30px;
	margon-top: 0px;

}


.activity--astro-photo {
	
	background: url(../img/svg/astophotography_icon.svg) no-repeat left center;

}

.activity--astronomy {
	
	background: url(../img/svg/astronomy_icon.svg) no-repeat left center;

}

.activity--running {
	
	background: url(../img/svg/trail_running_icon.svg) no-repeat left center;
	@include background-size(30px 30px);

}

.activity--sun {
	
	background: url(../img/svg/sunrise_icon.svg) no-repeat left center;
	@include background-size(28px 28px);

}





/* Body Copy */

.para-intro {
	
font-size: rem-calc(22);
letter-spacing: -0.5px;
font-weight: 300;

@include breakpoint(medium) {
	
	font-size: rem-calc(27);
	margin-top: rem-calc(25);
	margin-bottom: rem-calc(25);

	}

}

.byline {
	

	font-weight: 100;
	font-size: rem-calc(14);
	margin-bottom:0;
	padding-top: rem-calc(12.5);
	
	@include breakpoint(medium){
		
		padding-top: 0;

	}
}

p, ul, ol, {
	
	font-size: rem-calc(18px);
	font-weight: 300;
}


p, ul, ol, pre, table, blockquote {
  margin-top: 0px;
  margin-bottom: 27px;
}
ul ul, ol ol, ul ol, ol ul {
  margin-top: 0;
  margin-bottom: 0;
}


blockquote {
font-size: rem-calc(29);
font-style: italic;
margin-left: rem-calc(25);
font-weight:200

}


figure {
	
	margin: rem-calc(25 0);
	padding: 0;
	position:relative;
	

}

figcaption {
	
	font-size: 12px;
	line-height: 1.2;
	margin-top: rem-calc(12.5);
	font-weight: 400;
	
	@include breakpoint(medium) {
		
		margin-top: rem-calc(0);
		width: 110px;
		position: absolute;
		top:0;
		left: -125px;
		border-top: 1px solid $medium-gray;
		padding-top: rem-calc(12.5);
	
	}
	
}


#ditd--content-block {


	a {
		
		
		text-decoration: underline;
		font-weight: 300;
		
		&:hover {
			
	
		}

		&:visited {
			
			color: $dark-gray;
		}
	}
	
	ul, ol {

		@include breakpoint(medium){

			margin-left: rem-calc(50);
			
			}
		
		li {
			
			padding: rem-calc(6.25 0);
			
		}
	}
}


/* Lists */


.std-listings {
  padding-top: 3.125rem;
  margin: 0;
  list-style: none; }
  .std-listings h2 {
    font-weight: 500;
    margin-top: 0;
    line-height: 1.2;
    margin-bottom: 0.75rem; }
  .std-listings .detail-byline {
    font-size: 0.75rem;
    color: #dedff5;
    font-family: Consolas, "Liberation Mono", Courier, monospace; }
    @media screen and (min-width: 40em) {
      .std-listings .detail-byline {
        position: absolute;
        top: 20px;
        right: 0px;
        width: 12%; } }

.std-list-item {
  padding: 0.75rem 0;
  position: relative;
  border-top: 1px solid $white; }
  .std-list-item h2 {
    font-size: 1.8125rem; }


    ul.dest_list {
	

	list-style: none;
	margin-top: rem-calc(25);
	font-size: inherit;
	margin-left: 0;

	li {
		
		padding: rem-calc(6 0 6 0);
		  border-top: 1px solid rgba(255, 255, 255, 0.1) !important; 
			
	}

}



.spacer {
	
	width: 90px;
	border-width: 6px;
	border-color: $primary-color;
	padding-top: 0px;
	margin-top: rem-calc(25);
	margin-bottom: rem-calc(50);
	display: inline-block;
}


.spacer-white {
	
	@extend .spacer;
	
	border-color: $white;
	
}


.sidelist {

	background: $primary-color;
	color: $white;
	padding: rem-calc(12.5 12.5 37.5 12.5);
	margin-left:0;
	
		h4 {
			
			font-weight:700;
		}
	
	@include breakpoint(medium){
		
		background: none;
		color: $black;
		padding: rem-calc(0 25 0 0);
		font-size: rem-calc(16);
	
		
		}
	
	
	li {
		
		list-style:none;
		padding: rem-calc(12.5 0);
		border-bottom: 1px solid $medium-gray;
		
	}
}

.sidebar {
	

	@include breakpoint(medium) {
		
		h4 {
			
			margin-top: rem-calc(32);
		}
	}
}


.emphasise {
	
	font-weight: 600;
}

.didt--social-media {
	
	background: $primary-color;
	padding: rem-calc(6.25 12.5 3 12.5);
	display:inline-block;
	margin-left: 0;
	font-family: "dark-skies";
	font-size: rem-calc(28);
	
	li {
		
		list-style:none;
		display:inline;
		padding-right: rem-calc(12.5);
		
		&:last-child {
			
			padding-right: 0px;
		}

		a {
			
			color: $white;
			
			&:hover {
				
				color: $dark-gray!important;
			}
		}

	}



	i {
		
		color: $white;
		font-size: rem-calc(27);
	}
}



/*********************************************************************************************

Images

**********************************************************************************************/

.circle {
	
	 -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
}

.img-thumb {
	
	float: left;
    width: 6.25em;
    height: 6.25em;
    margin-right: 1.375em;
    margin-bottom: 1.375em;
	
}

.figure {
	
	margin:0;
}






/*********************************************************************************************

Controls

**********************************************************************************************/


.button {
	
	text-transform: uppercase;
}

.hollow {
	
	&:hover {
		
		color: $red!important;
		border: 1px solid $red!important;
	}
}


.transition {

	@include transition(all 0.2s ease-in-out);
}

a, a:visited {

	@extend .transition; 
	
	&:link {
		//Highlight links on iOS, Similar to :hover for mobile devices.
		-webkit-tap-highlight-color : rgba( 0, 0, 0, 0.3 );
	    
	}
}

.anchor-light {
	
	color:$white;
	
	&:hover {
		
		color:$red;
	}
}




/*********************************************************************************************

Header

**********************************************************************************************/

#ditd--header {
	
	position:relative;
	z-index:250;
	display: block;
	
}

.ditd--header-inner {

	background:$primary-color;
	
	@include breakpoint(medium) {
	background: none;
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	z-index:4;
	margin-top:0;
	background: rgba(0, 0, 0, 0.4);
	}
	
	
}

 .bottom-nav {
	
	margin-top: 20px;

}


.ditd--header-inner-solid {
	
	background: $primary-color;
	padding-top: rem-calc(12.5);
}


#logo {



	width: rem-calc(120);
	height: rem-calc(120);
	background:url(../img/discovery-in-the-dark-logo.png) no-repeat;
	@include background-size(120px auto);
	float: left;
	margin-top: rem-calc(10);
	
	@include breakpoint(medium) {
		
		width: rem-calc(130);
		height: rem-calc(130);
		@include background-size(130px auto);

	}

	@include breakpoint(large) {
		
		width: rem-calc(175);
		height: rem-calc(120);
		@include background-size(170px auto);

	}
	
}

#logo--pembrokeshire {
	
	@extend #logo;
	background:url(../img/discovery-in-the-dark-pembrokeshire.png) no-repeat;
}

#logo--snowdonia {
	
	@extend #logo;
	background:url(../img/discovery-in-the-dark-snowdonia.png) no-repeat;
}


#logo--brecon-beacons {
	
	@extend #logo;
	background:url(../img/discovery-in-the-dark-brecon.png) no-repeat;
}


#logo--cy {
	
	@extend #logo;
	background:url(../img/discovery-in-the-dark-logo-cy.png) no-repeat;
}



.media-card {
	
	background: $primary-color;
	color:$white;
	position: relative;
	margin: rem-calc(25 0);
	
	
}

.media-card-text{ 

	padding: rem-calc(25 25 25 0);
	
	a {
		
		color: inherit;
		

	}
	
	}



.nav-menu, .secondary-nav{
	
	li {
		
		display: inline-block;
		padding:rem-calc( 0 3.25);
		&:last-child {
			
			padding-right:0;
		}

		@include breakpoint(large) {
			padding:rem-calc( 0 6.25);
			}
	

		a {
		
		color: $medium-gray;
		font-weight:300;
		
			&:hover {
				
				color: $dark-gray;
			}
		
			@include breakpoint(medium) {
			font-size: rem-calc(16);
			}

			@include breakpoint(large) {
			font-size: rem-calc(20);
			}


		
		
	}

	.current {
		
		text-decoration: underline;
		}
	}
}


.top-nav-menu {

	margin-left:0;
	font-size: rem-calc(14);

	li {
		
		display: inline-block;
		padding:rem-calc( 0 6.25);
		text-transform: uppercase;
		
		a {
		
		color: $light-gray;
		font-weight:400;
		
			&:hover {
				
				color: $dark-gray;
			}
		
		}

		&:last-child {
		padding-right:0;
		}

	
	.current {
		
		color: $dark-gray;
	}

	}
}




.submenu {
	
	border:none;
	
	li {

		border-bottom: 1px solid  rgba(0, 0, 0, 0.4);
		padding: rem-calc(6 10);
		
		&:hover {
			
			background: rgba(255, 255, 255, 0.1);
		}
		
		&:last-child {
			
			border:none;
		}
		
		a{
		
		font-size: rem-calc(16);
		
		
		}

	}
}

.title-bar {
	
	background: none;
	margin-top: 40px;
	border: 1px solid rgba(255, 255, 255, 0.1);
	display: inline-block;
	float: right;
	font-size: 12px;
}

.trade {


	display: inline-block;
	font-size: rem-calc(14);
	color: $white;
	font-weight: 400;
	text-transform: uppercase;
	display: block;
	text-align: right!important;

}

/*********************************************************************************************

Navigation

**********************************************************************************************/


.side-menu {
	

	li {
		
		border-bottom: 1px solid $medium-gray;
	
	}
}

.side-nest {
	padding-bottom: rem-calc(25);
	
	li {
		
		border-bottom: none;
		
	}
}


.search {
	
	background: none;
	border-left: 0;
	border-right: 0;
	border-top:0;
	
	margin-bottom:20px;
	
}


.overview-info {


	li {
		
		
		@include breakpoint(medium) {
			background:url(../img/svg/chevron_icon.svg) no-repeat left;
			margin-left: 20px;
			@include background-size(15px);
			}
		}
		
	a {
		
		color: $white;
		padding-left: 10px;
		
		&:hover{
			
			color: $dark-gray;
		}
	}
}


/** Mobile Nav **/

.off-canvas-wrapper, .off-canvas {
	
 background: scale-color($primary-color, $lightness: -40%);

}
	

ul.off-canvas-nav, .off-canvas-lang {

	
	list-style:none;
	background: none;
	padding: 0;
	margin-left:rem-calc(25);

	
	li {

		padding: rem-calc(12.5 0);
		color: $dark-gray;
		
		
		a {
			
			color: $dark-gray;
		
			
			&:hover {
				
				color: $light-gray;
			}
		}
	}
}


.off-canvas-lang {
	
 li {
 	
 	padding-right: rem-calc(6.25);
 	
 .current {
 	
 	border: 1px solid rgba(255, 255, 255, 0.1);
 	padding: rem-calc(3.125 6.25);
 }
 }
}

.js-off-canvas-exit {
	
	background: rgba(0,0,0,0.8);
	z-index:300;
}
.close-button {

font-size: 2em;
	color: $light-gray!important;
		
		&:hover {
			
			color: $light-gray;
		}
}

/*********************************************************************************************

Content

**********************************************************************************************/

/* Elements */

.expanded {
	
	.text-area, .ditd--dest-card-info {
		
				padding: rem-calc(12.5 0 25 0);
			
				@include breakpoint(medium) {
			
			border-right: 1px solid $medium-gray;
			padding: rem-calc(25 25 50 25);

		}

		.text-area {
			
			padding: rem-calc(0);
			
			@include breakpoint(medium) {
			
			border-right: 1px solid $medium-gray;
			padding: rem-calc(25 25 50 25);

		}

		}
	}
}

.text-area, .ditd--dest-card-info  {
	
	
	position: relative;
	display: block;
	
	@include breakpoint(medium) {
		
		padding: rem-calc(25 0 50 0);

	}
}


.col-item, .ditd--dest-card {
	
	margin-top: rem-calc(0);

	hr {
		
		margin-top: 0px;
		
		width: 5%;
		display: inline-block;
	}

	.spacer {
		
		width: 50%;
		margin-bottom: rem-calc(12);
		
	}

	h2, h3 {
		
		margin-top: 0px;
		margin-bottom: 0px;
	}

	p {
		
		font-weight: 200;
	}

	@include breakpoint(medium) {

		margin-top: rem-calc(50);
		

	}
} 

.ditd--dest-card {
	
	margin-top: rem-calc(0);
	margin-bottom: rem-calc(0);
	
	&:last-child{
		
		margin-bottom:0;
	}

	@include breakpoint(medium) {

	margin-top: rem-calc(50);
	margin-bottom: rem-calc(75);
		

	}
	
	
}

.ditd--dest-card-info {


	p {
		
		margin-top: 0;
	}

	@include breakpoint(medium) {

		h2, h3 {
		
		margin-top: rem-calc(12);
		
		}
		

	}

	

	.spacer {
		
		width: 50%;
		margin-bottom: rem-calc(12);
		
	}
} 




/* Homepage */


.ditd--home-page-hero {
	
	background: $primary-color;
	color: $white;
	background: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0)), url(../img/img-example-16.jpg)  center no-repeat;
	position: relative;
	@include background-size(cover);
	height:400px;
	
	
	@include breakpoint(medium ) {

		height:100vh;
		
		}
	
	
}

.f-row {
	

}

#welcome {
	
	position:relative;
	display:block;
}




.ditd--hero-title {

 margin-top: rem-calc(0);
 
 	 margin-top: 0;
		 width:100%;		
		  position: absolute;
		  top: 45%;
		  transform: translateY(-50%);
  
  @include breakpoint(medium) {
		 margin-top: 0;
		 width:45%;		
		  position: absolute;
		  top: 45%;
		  transform: translateY(-50%);
		  color: $white;
		  z-index:200;
	
		}
}


/* Text Page */

.ditd--hero-byline {

	@include breakpoint(medium) {
		

	margin: rem-calc(80 0 75 0);
	border-top: 1px solid rgba(255,255,255, 0.25);
	padding-top: rem-calc(12.5);
	
	
	h4 {
		
		margin-top:0;
		
	}



	}
	

}

.ditd--hero-caption {

	position: absolute;
	bottom: 30px;
	font-weight: 400;

	color: $white;


	@include breakpoint(medium) {
		
	
		bottom: 80px;
		font-size: 24px;
		font-weight: 400;
		color: $white;
		z-index:200;
	
		}
}

.overview-info {
	
	

	list-style:none;
	display: block;
	color: $white;
	text-align: right;
	
	
	li {
		
	display:inline;
	padding-left: 12px;
	
	}
}


.ditd--text-page-hero {

	background: $primary-color;
	color: $white;
	background: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0)), url(../img/img-example-4.jpg)  center no-repeat;
	position: relative;
	@include background-size(cover);
	height: 300px;
	

	@include breakpoint(medium) {
	
		height:80vh;
	
	}
	
	
}




.ditd--content-intro {
	
	p {
		
		margin-top: 0px;
	}
}


#didt--activities-right {
	
	position:absolute;
	display:block;
	right:0;
	top:140px;
	padding:0;
	margin:0;

}


/* Hotspot Listings */



/*Video Hero */



#np--video-hero {
    position: relative;
    background: #000;
    overflow: hidden;
    height: 100vh;
}


#np--video-hero video {
  
  /* Make video to at least 100% wide and tall */
  min-width: 100%; 
  min-height: 100%; 
  
  /* Setting width & height to auto prevents the browser from stretching or squishing the video */
  width: auto;
  height: auto;
  
  /* Center the video */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
 
}


 video::-webkit-media-controls {
    display:none !important;
  }


.faux-tint {
	
	background: rgba(0, 0, 0, 0.4);
	position:absolute;
	width: 100%;
	height: 100vh;
	display:inline-block;
	z-index:10;

}

/* Content-page */


.view-all {
	
	@include breakpoint(medium) {
		
		padding-top: rem-calc(25);

	}
}

.ditd--content-page-hero {



		background: $primary-color;
		color: $white;
		background: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0)), url(../img/img-example-8.jpg)  center no-repeat;
		position: relative;
		@include background-size(cover);
		height:300px;

	@include breakpoint(medium) {
		
		height:100vh;
		
		}
	
	
}


.ditd--business-page-hero {

	background: $primary-color;
	color: $white;
	background: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0)), url(../img/img-example-9.jpg)  center no-repeat;
	position: relative;
	@include background-size(cover);
	height: 300px;
	

	@include breakpoint(medium) {
	
		height:60vh;
	
	}
	
	
}

/* News Feeds */

.didt--news-feed-row, .didt--news-list-row  {
	
	h3 {
		
		font-weight: 500;
		margin-bottom: rem-calc(12.5);
		margin-top: 0;
		
		a {
			
			&:hover {
				
				color: $red;
			}
		}
	}

	.excerpt {
		
		font-style: italic;
	}
}

.ditd--news-date {
	
   h3 {
	font-family: $font-family-monospace;
	font-size: rem-calc(18);
	margin-top: 9px;
	font-weight: 100;
	color: #777;
	}
}


.didt--news-list-row {

	margin-bottom: rem-calc(25);
	
	&:last-child{
		
		margin-bottom:0;
	}
	
	@include breakpoint(medium) {
		
	margin-bottom: rem-calc(50);

	}
	
}

.pagination {

	@include breakpoint(medium) {
		
		margin-top: rem-calc(50);

	}
	
	
}


/* Modal */

.ditd--additionals, .reveal {
	
	color: $white;
}

/* Newsletter */

.didt--newsletter-form {
	
	padding-bottom: rem-calc(25);
}


/* Footer */

.ditd--footer {

	color: $dark-gray;

	.section-wrap {
		
		padding: rem-calc(25 0);
	}

	.bt {
		
		border-top: 1px solid scale-color($primary-color, $lightness: 12%);
	}
	
	.side-menu {
		
		a {
			
			color: $dark-gray;
			padding: 6.25px 0;
			
			&:hover {
				
				color: $light-gray;
			}
		}

		li {
			
			border:none;
			
		}
	}
}



.f-destinations {

	li {

		a {

			display: inline-block;
		}
	}
}

.f-social {

	font-family: "dark-skies";
	display:inline;
	top:5px;
	position: relative;

}

.footer-newsletter {
	

	label {
		
		color: $dark-gray;
		padding-right: rem-calc(25);
		font-size: rem-calc(18);
		text-align: left;
		margin-left: 12px;
	}

	input.footer-email-input {
		
	
		background: none;
	
		
		color: $white;

		border: 1px solid $dark-gray;
	}

	.button {
		
		background: $dark-gray;
		color: $black;
	}



}

.sign-off {
	
	background: scale-color($primary-color, $lightness: -40%);
	color: $dark-gray;
	font-size: rem-calc(14);
	
	a {
		
		color: $dark-gray;
		text-decoration: underline;
	}
}



/* Spacing Overrides */

.t0 {
	
	margin-top: 0px;
	padding-top: 0px;
}

.b0 {

	@include breakpoint(medium) {
	
		margin-bottom: 0px;
		padding-bottom: 0px;
		
		}
}


.t-sp {
	
	margin-top: rem-calc(25);
	
}


.bt {
	
	border-top: 1px solid $medium-gray;
}

.br {

	@include breakpoint(medium) {
	
		border-right: 1px solid $medium-gray;
		
		}
}

.bb {
	
	border-bottom: 1px solid $medium-gray;
}

.m0 {
	
	margin:0!important;
}

.p0 {
	
	padding:0!important;
}

.t-col-0 {
	
	.col-item {
		
		margin-top:0;
	}
}


////Icon Font ////


@charset "UTF-8";

@font-face {
  font-family: "dark-skies";
  src:url("../fonts/dark-skies.eot");
  src:url("../fonts/dark-skies.eot?#iefix") format("embedded-opentype"),
    url("../fonts/dark-skies.woff") format("woff"),
    url("../fonts/dark-skies.ttf") format("truetype"),
    url("../fonts/dark-skies.svg#dark-skies") format("svg");
  font-weight: normal;
  font-style: normal;

}

[data-icon]:before {
  font-family: "dark-skies" !important;
  content: attr(data-icon);
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^="icon-"]:before,
[class*=" icon-"]:before {
  font-family: "dark-skies" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-twitter:before {
  content: "\61";
}
.icon-facebook-official:before {
  content: "\62";
}
.icon-instagram:before {
  content: "\63";
}
.icon-astophotography-icon-blk:before {
  content: "\64";
}
.icon-astronomy-icon-blk:before {
  content: "\65";
}
.icon-badgerwatching-icon-blk:before {
  content: "\66";
}
.icon-batwalking-icon-blk:before {
  content: "\67";
}
.icon-beginnersguide-icon-blk:before {
  content: "\68";
}
.icon-campingout-icon-blk:before {
  content: "\69";
}
.icon-compass-icon-blk:before {
  content: "\6a";
}
.icon-essentialgear-icon-blk:before {
  content: "\6b";
}
.icon-food-icon-blk:before {
  content: "\6c";
}
.icon-glowworm-icon-blk:before {
  content: "\6d";
}
.icon-ipod-icon-blk:before {
  content: "\6e";
}
.icon-jacket-icon-blk:before {
  content: "\6f";
}
.icon-mobile-phone-icon-blk:before {
  content: "\70";
}
.icon-trailrunning-icon-blk:before {
  content: "\71";
}
.icon-torch-icon-blk:before {
  content: "\72";
}
.icon-thermos-flask-icon-blk:before {
  content: "\73";
}
.icon-sunrisesunsetwatching-icon-blk:before {
  content: "\74";
}
.icon-shootingstar-cometnights-icon-blk:before {
  content: "\75";
}
.icon-scienceintheskies-icon-blk:before {
  content: "\76";
}
.icon-notepad-icon-blk:before {
  content: "\77";
}
.icon-nighttimewalks-icon-blk:before {
  content: "\78";
}
.icon-mythslegends-icon-blk:before {
  content: "\79";
}
.icon-mountainbiking-icon-blk:before {
  content: "\7a";
}

.ditd--icon-font {
	
	font-family: "dark-skies";
	position:relative;
	top:5px;
	padding-right: 5px;
	display:block;
}

